// Mon Scroll_fonction
$(window).scroll(function() {
    if ($(window).scrollTop() >= 0){
        $('body').addClass('navscroll');
    }
    if ($(window).scrollTop() >= 100 && $(window).scrollTop() <= 2700){
        $('body').addClass('navscroll_home');
    }else {
        $('body').removeClass('navscroll_home');
    }
    if ($(window).scrollTop() >= 700){
        $('body').addClass('navscroll_simulation');
    }else {
        $('body').removeClass('navscroll_simulation');
    }
});